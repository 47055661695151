<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '90vw'}"
    :style="{width: '90vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i
            class="pi pi-file"
            style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Información - {{ 'Solicitud de Traslado' }}</strong>
        </h4>
      </div>
    </template>

    <div class="w-full text-xxs">
      <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
        <div class="grid grid-rows-4 gap-2">
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Código Cliente/Entidad: </strong>
            </div>
            <div class="col-span-3">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.CardCode"
                disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Nombre Cliente/Entidad: </strong>
            </div>
            <div class="col-span-3">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.CardName"
                disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>N. Solicitud: </strong>
            </div>
            <div class="col-span-3">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.DocNum"
                disabled/>
            </div>
          </div>
        </div>

        <div class="grid grid-rows-4 gap-2">
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de Contabilización: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.TaxDate"
                disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de documento: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.DocDate"
                disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de vencimiento: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.DocDueDate"
                disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Estado: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.estadoNombre"
                disabled/>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60 mt-8">
        <div class="grid grid-rows-2 gap-2">
        </div>
        <div class="grid grid-rows-2 gap-2">
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>De Almacén: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.BodegaOrigen"
                disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Almacén Destino: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <InputText
                class="p-inputtext-xxs inputsHeader text-xxs"
                v-model="dataSolicitudTraslado.BodegaDestino"
                disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="width: 100%"
      class="mt-6"
    >
      <DataTable
        :value="dataSolicitudTraslado.detalle"
        class="p-datatable-sm text-xxs"
        showGridlines
        dataKey="id"
        responsiveLayout="scroll"
        scrollHeight="500px"
      >
        <template #empty>
          No hay datos para mostrar
        </template>
        <Column
          field="LineNum"
          header="Linea"
          headerStyle="justify-content: center;"
          style="min-width:4rem"
          bodyStyle="text-align: center">
          <template #body="{data}">
            <div>{{ data.LineNum }}</div>
          </template>
        </Column>
        <Column
          field="ItemCode"
          header="Artículo"
          headerStyle="justify-content: center;"
          style="min-width:30rem"
          bodyStyle="text-align: center">
          <template #body="{data}">
            <div class="grid grid-cols-1 w-full">
              <div class="flex justify-between items-center my-1">
                <div>
                  <strong>Código Mx: </strong>{{ data.ItemCode }}
                </div>
                <div class="flex gap-2">
                  <Badge
                    v-if="data.ReqMarca"
                    :value="`Req. marca`"
                    class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                  <Badge
                    v-if="data.Controlado"
                    value="Controlado"
                    class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                  <Badge
                    v-if="data.Regulado"
                    value="Regulado"
                    class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                  <Badge
                    v-if="data.cadenaFrio"
                    value="Cadena Frio"
                    class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                </div>
              </div>
              <div class="flex">
                <span><strong>Nombre: </strong>{{ data.ItemName }}</span>
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="Quantity"
          header="Cantidades"
          headerStyle="justify-content: center;"
          style="min-width:8rem"
          bodyStyle="text-align: center">
          <template #body="{data}">
            <div class="flex flex-col">
              <div>
                <strong>Cant. Stock: </strong>{{ $h.formatNumber(data.QuantityStockBodOrigen) }}
              </div>
              <div>
                <strong>Cant. Solicitada: </strong>{{ $h.formatNumber(data.Quantity) }}
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="BodOrigen"
          header="Bod. Origen"
          headerStyle="justify-content: center;"
          style="min-width:8rem"
          bodyStyle="text-align: center">
        </Column>
        <Column
          field="BodDestino"
          header="Bod. Destino"
          headerStyle="justify-content: center;"
          style="min-width:8rem"
          bodyStyle="text-align: center">
        </Column>
        <Column
          field="UnitPrice"
          header="Precio"
          headerStyle="justify-content: center;"
          style="min-width:8rem"
          bodyStyle="text-align: center">
          <template #body="{data}">
            <div>{{ $h.formatCurrency(data.UnitPrice) }}</div>
          </template>
        </Column>
        <Column
          field="LineTotal"
          header="Total Linea"
          headerStyle="justify-content: center;"
          style="min-width:8rem"
          bodyStyle="text-align: center">
          <template #body="{data}">
            <div>{{ $h.formatCurrency(data.LineTotal) }}</div>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="w-full mt-4">
      <div class="w-full text-xxs grid grid-cols-1 lg:grid-cols-2 gap-2 xl:gap-8">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 lg:gap-x-10 xl:gap-x-60 lg:col-span-2">
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Comentarios: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              disabled
              :autoResize="true"
              v-model="dataSolicitudTraslado.Comments"
              rows="2"
              cols="40"
            />
          </div>
          <div class="flex flex-col">
            <p class="mb-2">
              <strong>Entrada de diario: </strong>
            </p>
            <Textarea
              class="p-inputtext-xxs"
              disabled
              :autoResize="true"
              v-model="dataSolicitudTraslado.JrnlMemo"
              rows="2"
              cols="40"
            />
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>

  </Dialog>
</template>

<script setup>
import { defineExpose, ref } from 'vue'

const displayModal = ref(false)
const dataSolicitudTraslado = ref()

// Funcion para abrir el modal
const openModal = (values) => {
  dataSolicitudTraslado.value = values
  displayModal.value = true
}

// Se expone la funcion para abrir el modal, para que pueda ser llamado desde el componente padre
defineExpose({
  openModal
})
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}
</style>
